html {
  /* 
   this defines what 1rem IS
   default-font size = 16px
  1 rem == 10px 
  10px / 16px = 62.5%
  */
  font-size: 62.5%;

  @media (max-width: 80em) {
    /* 
    1280px
         1rem = 9px, 9px / 16 = 56.25% 
         */

    font-size: 56.25%;
  }
  @media (max-width: 60em) {
    /* 
     960px
     width <900
   1rem = 8px, 8px / 16 = 50% */
    font-size: 50%;
  }

  @media (min-width: 120em) {
    /* 
    1920px
         1rem = 12px, 12px / 16 = 75% 
     
    font-size: 75%;
     */
  }
  @media (max-width: 37.5em) {
    /* 600px */
  }
}
html,
body {
  scroll-behavior: smooth;
}

.App {
  direction: rtl;
  /* color: #ca2d78; */
}
::-webkit-scrollbar {
  width: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(48, 51, 53);
}

@media (max-width: 768px) {
  .add-property-responsive {
    grid-template-columns: 100% !important;
    margin: 0 !important;
  }
  .hide-at-mobile {
    display: none !important;
  }
  .show-side-drawer {
    display: block !important;
  }
  .add-property-container {
    padding: 5px !important;
  }
}

.MuiOutlinedInput-input,
.MuiInputLabel-outlined,
.MuiInputLabel-animated,
.MuiFormControl-root,
.MuiInputBase-root,
.MuiStepLabel-label,
.MuiFormLabel-root,
.MuiImageListItemBar-title,
.Toastify__toast-body {
  font-size: 1.4rem !important;
}

.MuiFormHelperText-root {
  font-size: 1rem !important;
}
